
<footer class="foot"> 
    <a href="https://www.linkedin.com/in/mark-johnston-a9b70117b/">
      <img src="assets/img/linkedin.svg" alt="My LinkedIn Profile" width=30 height=30> <br/>
    </a> 
    <a href="https://github.com/MJ9001">
      <img src="assets/img/github.svg" alt="My GitHub Profile" width=30 height=30> <br/>
    </a> 
    <a href="mailto:me@markjohnston.dev">
      <img src="assets/img/email.svg" alt="My Email Address" width=30 height=30> <br/>
    </a> 
    <p>© Mark Johnston 2020</p>
</footer>