<body>
    <div>
        <h1>About:</h1>

        <p>Hi, I'm Mark Johnston. A recent Computer Engineer graduate with a strong concentration in software. I'm passionate about technology, software engines, embedded systems, networking, and entrepreneurship.</p>

        <p>I've been working with code for the majority of my life. I started with .Net and Java in my middleschool and highschool years and later learned C++, ASM, and the depths of computers and embedded systems. In university I honed my development style and learned a deeper understanding of computer systems. I now enjoy working on systems of all levels, whether it be reversing a program's machine code and writing patches for it, or writing a quick website. </p>

        <p>Through my experience interning at Boeing, tutoring C++ classes at VCU, and my academic experiences I've learned how to work as both a effective team player and being a independent developer. I've also learned countless technologies and can very quickly learn new styles and technologies.</p>

        <p>Beyond my academic and professional life, I enjoy machining, wood working, designing, cooking/baking, gaming, spending time with friends, and working on independent software projects.</p>
    </div>
</body>