<div class="toolbar">
  
  <p class="textheader">Mark Johnston</p>
    <div *ngFor="let page of pages.reverse()" class="toolnav">
      <nav>
        <a *ngIf="page.dropdown == null">
          <app-tile [Route]=page.route [Name]=page.name></app-tile>
        </a>
        <div *ngIf="page.dropdown != null" class="dropdown">
          <app-tile [Route]=page.route [Name]=page.name></app-tile>
          <object *ngFor="let drops of page.dropdown" [routerLink]="drops.route" routerLinkActive="active" id="a"> 
            <app-tile [Route]=drops.route [Name]=drops.name id="b"></app-tile>
          </object>
        </div>
      </nav>
      
    </div>
    <!--<object class="seperator"></object>-->
  </div>
  