<body>

     <div class="topHead">
         <h1>Mark Johnston</h1>
         <h2>Computer Engineering Graduate</h2>
     </div>
     <div class="about">
         <hr>
     </div>
     <div class="about">
         <!-- <h1>About</h1> -->
         <h3 id="topText">I've developed on every level of machines. From the digital logic, to assembly, to a compiler, to high level language.</h3>
     </div>
     <div class="about">
         <hr>
     </div>
     <div class="breaker"> <hr> </div>
    <div class="coreStall">
        <object class="plot">
            <h2>Check out my projects</h2> 
            <img src="assets/img/projects.svg" width=100 height=100/>
            <h3>I've worked on a lot of various projects, here's some of my favorite along with my GitHub</h3>    
            <button class="button" [routerLink]="'/projects'" routerLinkActive="active">Learn More</button>
        </object>

        <object class="plot">
            <h2>Resume</h2> 
            <img src="assets/img/resume.svg" width=100 height=100/>
            <h3 id="body"> My automatically updated resume.</h3>
            <button class="button" [routerLink]="'/resume'" routerLinkActive="active">Learn More</button>
        </object>

        <object class="plot">
            <h2 id="head">My Style</h2> 
            <img src="assets/img/style.svg" width=100 height=100/>
            <h3 id="body">Find out about me. Academically, professionally, and personally.</h3>
            <button class="button" [routerLink]="'/about'" routerLinkActive="active">Learn More</button>
            
        </object>
    </div>
</body>