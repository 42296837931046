<style>
  :host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    content: "";
    padding-top: 50px;
    flex: 1;
    flex-wrap: wrap;
  }
</style>

<body>
  <div>
    <router-outlet></router-outlet>
    <app-navigator></app-navigator>
  </div>
  <app-footer></app-footer>
</body>