<body>
    <div>
        <h1>Contact info:</h1>

        <p><a href="mailto:me@markjohnston.dev">Email:</a> me@markjohnston.dev</p>

        <p><a href="tel:+8044567991">Phone:</a> (804)456-7991</p>

        <p><a href="https://www.linkedin.com/in/mark-johnston-a9b70117b/">LinkedIn</a></p>

    </div>
</body>